<template>
	<disclosureMobile v-if="isMobile"></disclosureMobile>
	<div v-else class="disclosure">
		<crumbs :item="title" />
		<div class="price">
			<main>
				<p>安联投资连结保险账户每日价格公告</p>
				<div class="tab">
					<div class="account">
						<span>产品名称：</span>
						<el-select
							v-model="countType"
							placeholder="请选择"
							@change="onChangeCountType"
						>
							<el-option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</div>
					<div class="pricingDay">计价日:&ensp;&ensp;{{ pricingDay }}</div>
					<div class="content">
						<vxe-table
						class="mytable-scrollbar"
							stripe
							border
							round
							ref="xTable"
							max-height="400"
							:loading="loading"
							:row-config="{ isHover: true }"
							:data="tableData"
						>
							<vxe-column type="seq" width="60"></vxe-column>
							<vxe-column field="name" title="账户"></vxe-column>
							<vxe-column
								field="num"
								title="投资单位价值"
								sort-type="number"
								sortable
							></vxe-column>
						</vxe-table>
					</div>
				</div>
				<div class="view_btn">
					<div class="btn">
						<a @click="details()">
							<p>历史价格公告</p>
						</a>
						<a
							target="_blank"
							href="https://www.allianz.com.cn/allianz/9ff2da1421374427916a893c10387c65.pdf"
						>
							<p>查看账户说明</p>
						</a>
					</div>
				</div>
			</main>
		</div>
		<div class="report">
			<div class="report_main">
				<p>安联投资连结保险账户月度报告</p>
				<ul>
					<li v-for="(item, index) in report" :key="index">
						<img src="~@/static/images/right.png" alt="" />
						<a :href="item.fileUrl" target="_blank">{{ item.fileTitle }}</a>
					</li>
				</ul>
				<div class="paging">
					<p>共有{{ total }}条记录</p>
					<div>
						<img
							@click="getPageNumAReduction()"
							src="~@/static/images/l_arrow@2x.png"
							alt=""
						/>
						<span>{{ pageNum }}/{{ pages }}</span>
						<img
							@click="getPageNumAdd()"
							src="~@/static/images/ic_arrow_black@2x.png"
							alt=""
						/>
					</div>
				</div>
				<!--        <div class="view_btn">-->
				<!--          <div class="btn">-->
				<!--            <p>更多月度报告</p>-->
				<!--          </div>-->
				<!--        </div>-->
			</div>
		</div>
		<div class="login_box">
			<div class="login">
				<div>
					<a
						target="_blank"
						href="https://pts.allianz.com.cn/azclportal/faces/cplogin"
					>
						<p>账户登录</p>
						<img
							src="~@/static/images/secondaryMenu/news/youjiantou@2x.png"
							alt=""
						/>
					</a>
				</div>
				<div>
					* 登录客户专区，可以查询到本人投资连结保险保单的相关信息，详情包括保费
					内容、缴费记录、投保人信息、投资账户单位数、投资账户价值、每月扣除额等。
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import crumbs from "@/components/crumbs.vue";
	import disclosureMobile from "./mobile/disclosureMobile.vue";
	import { getFileListPre } from "@/api/public";
	import common from '@/utils/common'
	import {
		getNewPreDisclosureTable,
		getNewPreDisclosureOption,
	} from "@/api/preview/predisclosure";

	export default {
		name: "disclosure",
		data() {
			return {
				isMobile:false,
				title: [
					{
						title: "新闻公告",
						link: "",
					},
					{
						title: "投连险信息披露",
						link: "/disclosure",
					},
				],
				report: [],
				iframeWin: {},
				url: "",
				// url: "https://az-uat.chengstarkj.com/home",
				value: "",
				total: 0,
				pageNum: 0,
				pages: 0,
				countType: "", //选择框选中值
				options: [], //选择框选项
				pricingDay: "", //计价日
				tableData: [], //表格内容
				loading: false, //表格加载中
			};
		},
		components: { crumbs, disclosureMobile },
		methods: {
			//跳转详细页面
			details() {
				if (!this.countType) {
					this.$message.warning("请先选择产品!");
					return;
				}
				this.$router.push({
					name: "disclosureDetails",
					params: {
						countType: this.countType,
					},
				});
				localStorage.setItem("disclosureCovercode", this.countType);
			},
			getPageNumAdd() {
				if (this.pageNum < this.pages) {
					this.pageNum = this.pageNum + 1;
					this.request(this.pageNum);
				} else {
					this.$message.warning("已经是最后一页了");
					console.log("no");
				}
			},
			// 上一页
			getPageNumAReduction() {
				if (this.pageNum <= 1) {
					this.$message.warning("已经是第一页了");
				} else {
					this.pageNum = this.pageNum - 1;
					this.request(this.pageNum);
				}
			},
			async request(num) {
				try {
					const [timeStamp, sign] = this.$getSign();
					const res = await getFileListPre({
						timeStamp,
						sign,
						fileCategory: "AZ01505",
						pageNum: num,
						// fileTitle: title,
					});
					if (res.data.code == 200) {
						console.log(res.data);
						this.report = res.data.rows;
						this.pageNum = res.data.pageNum;
						this.pages = res.data.pages;
						this.total = res.data.total;
					}
					if (
						res.data.code == 401 ||
						res.data.code == 404 ||
						res.data.code == 500
					) {
						this.$router.replace("/found404");
					}
				} catch (error) {
					// this.$message.error(error);
					console.log(error);
				}
			},
			//选择框
			onChangeCountType() {
				this.getNewPreDisclosureTable(this.countType);
			},

			//获取选项数据
			async getNewPreDisclosureOption() {
				const [timeStamp, sign] = this.$getSign();
				const {
					data: { rows },
				} = await getNewPreDisclosureOption({ timeStamp, sign });
				this.options = rows.map((item) => ({
					value: item.COVER_CODE,
					label: item.FULL_NAME,
				}));
				this.countType = this.options ? this.options[0].value : ""; //默认下拉值
				this.getNewDisclosureTable(this.options[0].value); //默认下拉值对应的表格数据
			},

			//获取表格数据
			async getNewPreDisclosureTable(coverCode = "EHY01") {
				this.loading = true; //加载
				const [timeStamp, sign] = this.$getSign();
				const {
					data: { rows },
				} = await getNewPreDisclosureTable({ timeStamp, sign, coverCode });
				this.loading = false; //加载
				this.pricingDay = rows[0].VALUED_UPTO.substring(0, 10); //计价日
				this.tableData = rows.map((item) => ({
					//数据
					id: item.FUND_ID,
					name: item.FUND_FULL_NAME,
					num: item.BID_PRICE ? item.BID_PRICE.toFixed(4) : ""
				}));
			},
		},
		created() {
			this.isMobile = common.isMobile();
			var isPad = common.isPad();
			if(isPad){
				this.isMobile = false
			}
            console.log(this.isMobile);
            if (!this.isMobile) {
				this.request();
				this.getNewPreDisclosureOption(); //初始化加载选项数据
            }
			// this.request();
			// this.getNewPreDisclosureOption(); //初始化加载选项数据
			// this.getNewPreDisclosureTable()  //初始化加载表格数据
		},
		mounted() {
			this.url =
				"https://www.allianz.com.cn/ProductValueQuery/faces/coverPriceCurrent.jspx";
			// this.url="https://az-uat.chengstarkj.com"
		},
	};
</script>

<style lang="less" scoped>
.mytable-scrollbar ::-webkit-scrollbar {
	width: 5px ;
  } 
	li {
		list-style: none;
	}

	a {
		text-decoration: none;
		color: #000;
	}

	.disclosure {
		.price {
			width: 100%;

			> main {
				width: 80%;
				// height: 26vw;
				margin: auto;

				> p {
					text-align: center;
					font-size: 4rem;
					margin: 4rem 0;
					color: #003781;
				}

				> .tab {
					//width: 60%;
					width: 106rem;
					background: #edeff5;
					margin: auto;
					padding: 0 5rem;
					padding: 0 5rem 5rem;

					.account {
						padding: 30px 10px 10px 40px;
						font-size: 1.8rem;

						//select
						/deep/ .el-input__inner {
							width: 50rem;
							height: 3.5rem;
							line-height: 3.5rem;
						}

						//select-item
						/deep/ .el-input__suffix {
							.el-input__suffix-inner {
								.el-input__icon {
									line-height: 3.5rem;
								}
							}
						}
					}

					.pricingDay {
						padding: 10px 10px 10px 40px;
						font-size: 1.8rem;
					}

					.productName {
						display: flex;
						align-items: center;
						padding: 0 2rem;

						> div {
							margin: 1rem 3rem;
						}
					}

					.time {
						font-size: 2rem;
						padding: 1rem 5rem;
					}

					> .table {
						width: 90%;
						margin: auto;
					}
				}
			}
		}

		.view_btn {
			> .btn {
				font-size: 2rem;
				display: flex;
				justify-content: center;
				color: #fff;
				width: 35%;
				margin: auto;
				justify-content: space-between;

				> a {
					color: #fff;

					> p {
						padding: 1.5rem 3rem;
						background-color: #003781;
						margin: 5rem 1rem;
						cursor: pointer;
					}
				}
			}
		}

		.report {
			width: 100%;

			.report_main {
				border-top: 1px solid;

				width: 50%;
				margin: auto;

				> p {
					font-size: 3rem;
					margin: 4rem 0;
					text-align: center;
				}

				> ul {
					margin-bottom: 4rem;

					> li {
						display: flex;
						align-items: center;
						font-size: 1.5rem;
						height: 4rem;
						line-height: 4rem;
						color: #004b98;
						cursor: pointer;

						> img {
							width: 15px;
							height: 10px;
							margin-right: 10px;
						}

						> a {
							text-decoration: none;
							color: #003781;
							font-size: 2rem;
						}
					}
				}
			}
		}

		.login_box {
			width: 100%;

			.login {
				width: 50%;
				height: 14.5vw;
				margin: 2rem auto 9rem;
				background: url("~@/static/images/secondaryMenu/news/bg_kehu_login@2x.png");
				background-size: 100% 100%;
				color: #fff;
				padding: 5rem 8rem;
				box-sizing: border-box;

				> div:first-child {
					font-size: 4rem;
					display: flex;
					align-items: center;
					cursor: pointer;

					> a {
						color: #fff;
						display: flex;
						align-items: center;

						> img {
							width: 30px;
							height: 25px;
							margin-left: 2rem;
						}
					}
				}

				> div:last-child {
					font-size: 1.5rem;
					width: 75%;
					margin-top: 5rem;
				}
			}
		}

		::v-deep .paging {
			width: 100%;
			padding: 0;
			margin: 2rem 0;
		}
	}
</style>
