import http from "@/utils/request.js";

/**
 * @methods 请求预览信息披露页面下拉选项数据
 */
export const getNewPreDisclosureOption = (params) => {
	return http({
		url: "/product/getUlList",
		method: "get",
		params,
	});
};

/**
 * @methods 请求预览信息披露页面表格数据
 */
export const getNewPreDisclosureTable = (params) => {
	return http({
		url: "/product/getFundPriceByCover",
		method: "post",
		data:params
	});
};
